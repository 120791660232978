import { render, staticRenderFns } from "./review.vue?vue&type=template&id=8f2c3b20&scoped=true&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform"
import script from "./review.vue?vue&type=script&lang=js"
export * from "./review.vue?vue&type=script&lang=js"
import style0 from "./review.vue?vue&type=style&index=0&id=8f2c3b20&prod&lang=less"
import style1 from "./review.vue?vue&type=style&index=1&id=8f2c3b20&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f2c3b20",
  null
  
)

export default component.exports