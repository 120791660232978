import {isEmpty} from '../utils'
import {URL} from '../../../config'

/**
 * 删除正式商品
 */
export function deleteOfficialGoods(id){
  let data = {}
  if(typeof id === 'object'){
    data.goodsId = id.baseGoodsInfo.id
  }else {
    data.goodsId = id
  }
  if(isEmpty(data.goodsId)) return this.$message.error('商品id 异常')
  return this.$confirm('此操作将永久删除该商品, 是否继续?','确认删除',{
    confirmButtonText: '删除',
    type: 'error'
  }).then(()=>{
    this.loading = true
    return this.axios.post(URL.supplierGoods.deleteGoods,this.qs.stringify(data))
  }).finally(()=>{
    this.loading = false
  })
}

/**
 * 删除草稿商品
 */
export function deleteDraftGoods(id){
  let data = {}
  if(typeof id === 'object'){
    data.id = id.draftId
  }else {
    data.id = id
  }
  if(isEmpty(data.id)) return this.$message.error('draftId 异常')
  return this.$confirm('此操作将永久删除该申请, 是否继续?','确认删除',{
    confirmButtonText: '删除',
    type: 'error'
  }).then(res=>{
    this.loading = true
    return this.axios.post(URL.supplierGoods.deleteDraftOrReviewGoods,this.qs.stringify(data))
  }).finally(res=>{
    this.loading = false
  })
}

/**
 *撤销商品审核
 */
export function cancelReviewGoods(id) {
  let data = {}
  if(typeof id === 'object'){
    data.id = id.draftId
  }else {
    data.id = id
  }
  if(isEmpty(data.id)) return this.$message.error('draftId 异常')
  return this.$confirm('商品撤销审核后进入修改页面, 是否继续?','确认撤销',{
    confirmButtonText: '撤销',
    type: 'error'
  }).then(()=>{
    this.loading = true
    return this.axios.post(URL.supplierGoods.cancelReviewGoods,this.qs.stringify(data))
  }).finally(()=>{
    this.loading = false
  })
}
