<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="pages" v-loading="loading">
    <table-height-auto>
      <template slot="before">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-select v-model="searchData.searchType"
                       placeholder="请选择（上下架）"
                       style="width: 120px">
              <el-option
                v-for="item in searchKeyType"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-input class=""
                      placeholder="搜索关键字"
                      prefix-icon="el-icon-search"
                      v-model="searchData.searchValue"
                      @keyup.enter.native="search"
                      style="width: 260px">
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-select v-model="searchData.status"
                       placeholder="审核状态"
                       @change="search"
                       style="width: 120px">
              <el-option
                v-for="item in goodsReviewStatusArray"
                :disabled="item.disabled"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-cascader class="width-all"
                         ref="region"
                         v-model="classifyValue"
                         :options="[{id:'',name:'全部分类'}].concat(classifyList)"
                         :props="{checkStrictly: true,value:'id',label:'name'}"></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" round @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template v-slot:table="row">
        <el-table
          :data="tableData"
          :height="row.heightText"
          v-if="row.height">
          <el-table-column
            prop="id"
            label="审核编号"
            width="200">
          </el-table-column>
          <el-table-column
            label="封面图"
            width="100">
            <template slot-scope="scope">
              <img class="goods-cover" :src="scope.row.submitParamData.baseGoodsInfo.cover">
            </template>
          </el-table-column>
          <el-table-column
            prop="submitParamData.baseGoodsInfo.name"
            label="商品名称"
            width="">
          </el-table-column>
          <el-table-column
            prop="submitParamData.baseGoodsInfo.name"
            label="商品类型"
            width="120">
            <template slot-scope="scope">
              {{businessTypearr[scope.row.submitParamData.baseGoodsInfo.businessType]}}
            </template>
          </el-table-column>
          <el-table-column
            prop="submitParamData.baseGoodsInfo.brandName"
            label="品牌"
            width="100">
          </el-table-column>
          <el-table-column
            prop="submitParamData.baseGoodsInfo.cateDetails"
            label="分类"
            width="200">
          </el-table-column>
          <el-table-column
            prop="reviewTime_text"
            label="审核时间"
            width="200">
          </el-table-column>
          <el-table-column
            label="审核状态"
            width="100">
            <template slot-scope="scope">
              {{goodsReviewStatus[scope.row.status]}}
            </template>
          </el-table-column>
          <el-table-column
            label="审核备注"
            width="140">
            <template slot-scope="scope">
              {{scope.row.reviewRemark}}
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot-scope="scope">
              <el-button size="mini" icon="el-icon-view"  @click="showAddNew(scope.row)">详情</el-button>
              <el-button size="mini" icon="el-icon-view"  type="primary"  @click="editDraft(scope.row)" v-if="scope.row.status === 4">编辑</el-button>
              <el-button type="danger" size="mini" icon="el-icon-delete"  @click="cancelReviewGoods(scope.row)" v-if="scope.row.status === 2">撤销审核</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template slot="after">
        <div class="paging-row">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalListNum">
          </el-pagination>
        </div>
      </template>
    </table-height-auto>

    <look-goods-details title="商品详情"
                        :visible.sync="visibleAddNew"
                        :data="editGoodsData">
    </look-goods-details>

  </div>
</template>

<script>
  import {URL} from '../../../config'
  import {disposeSearchData, isEmpty , parseTimeArray} from '../../../assets/js/utils'
  import {disposeTreeData} from '../../../assets/js/goodsClassify'
  import {goodsReviewStatus, goodsReviewStatusArray} from '../../../assets/js/status'
  import {cancelReviewGoods} from '../../../assets/js/supplier/goods'

  import lookGoodsDetails from '../../../components/public/lookGoodsDetails/lookGoodsDetails.vue'

  export default {
    name: 'supplierGoodsReview',
    components: {
      lookGoodsDetails,
    },
    data(){
      return {
        loading: false,
        /* 数据表格 */
        goodsReviewStatus,
        goodsReviewStatusArray:goodsReviewStatusArray.filter(res=>[0,2,3,4].includes(res.id)),
        searchKeyType:[
          {id: 'goodsId', name: '商品编码'},
          {id: 'goodsName', name: '商品名称'},
          {id: 'brandName', name: '品牌名称'},
        ],
        classifyList:[],
        classifyValue:[''],
        searchData:{
          status:0,
          searchType:'goodsName',
          searchValue:'',
          firstCateId:'',// 一级分类ID
          secCateId:'',// 2级分类ID
          thirdCateId:'',// 3级分类ID
        },
        tableData: [], // 数据列表
        currentPage: 1, // 当前页码
        totalListNum: 0, // 列表总条量
        pageSize: 10, // 每页显示得条数
        /* 新增属性组弹窗 */
        visibleAddNew:false,
        editGoodsData:{},
        businessTypearr:['','零售商品','包装','运营商品','产品拍图']
      }
    },
    created(){
      this.parseQuery();
      this.getListClassify()
      this.getList();
    },
    methods:{
      parseQuery(){
        let params = this.$route.query;
        if(params['status'] === '2'){
          this.searchData.status = 2
        }
        if(params.currentPage){
          this.currentPage = parseInt(params.currentPage)||1;
        }
      },
      /**
       * 拼接搜索参数
       */
      jointSearchData(type){
        let data = {
          pageNo:this.currentPage,
          pageSize:this.pageSize,
        };
        data = Object.assign(data,this.searchData);
        disposeSearchData(data)
        return data;
      },
      /**
       * 搜索
       */
      search(type){
        this.currentPage = 1;
        this.getList()
      },
      /**
       * 查询列表数据
       */
      getList(){
        return new Promise((resolve, reject) => {
          let data = this.jointSearchData();
          this.loading = true;
          this.axios.get(URL.supplierGoods.reviewList,{params:data}).then(res=>{
            this.totalListNum = Number(res.data.total);
            res.data.records.forEach(item=>{
              if(item.submitParamJson){
                let obj
                try {
                  obj = JSON.parse(item.submitParamJson)
                  obj.draftId = item.id
                }catch (e) {
                  obj = {}
                }
                if(item.reviewRemark) obj.reviewRemark = item.reviewRemark
                if(item.status) obj.reviewStatus = item.status
                if(item.createTime) obj.baseGoodsInfo.createTime = item.createTime
                item.submitParamData = obj
              }
            })
            this.tableData = res.data.records;
            parseTimeArray(this.tableData,["reviewTime"]);
            resolve(res);
          }).catch(res=>{
            this.tableData = [];
            // reject(res);
          }).finally(res=>{
            this.loading = false;
          })
        })
      },
      /**
       * pageSize 改变时会触发：选择每页显示条数
       */
      handleSizeChange(val,type){
        this.pageSize = val;
        this.currentPage = 1;
        this.getList()
      },
      /**
       * currentPage 改变时会触发：选择页数
       */
      handleCurrentChange(val,type){
        this.currentPage = val;
        this.getList();
      },
      /**
       * 获取分类列表
       */
      getListClassify(){
        let data = {
          status:1,
        };
        this.loading = true;
        // 原逻辑 查询商家选择的品牌分类
        // this.axios.get(URL.supplierGoods.getSupplierBrandCateList,{params:data}).then(res=>{
        //   this.classifyList = disposeTreeData(res.data);
        //   return Promise.resolve(res);
        // }).catch(()=>{
        //   this.classifyList = []
        // }).finally(()=>{
        //   this.loading = false;
        // })
        // 查看全部分类
        this.axios.get(URL.supplierBrand.searchCommonCategory,{params:{}}).then(res=>{
          this.classifyList = disposeTreeData(res.data);
          return Promise.resolve(res);
        }).catch(()=>{
          this.classifyList = []
        }).finally(()=>{
          this.loading = false;
        })
      },
      /**
       * 显示 编辑属性组弹窗
       * @param data  {object}  //编辑时需要传入编辑的数据对象
       * @param type  {number}  //编辑类型
       * */
      showAddNew(data){
        if(typeof data === 'object' && data.submitParamData){
          this.editGoodsData = data.submitParamData
        }else{
          this.editGoodsData = {}
        }
        this.visibleAddNew = true
      },
      hideAddNew(){
        if(!isEmpty(this.editGoodsData)) this.editGoodsData = {}
        if(this.visibleAddNew) this.visibleAddNew = false;
      },
      editGoodsSuccess(res){
        this.getList()
        this.hideAddNew()
      },
      /**
       * 编辑草稿箱
       */
      editDraft(data){
        this.$router.push({
          path:'/supplierEditGoods',
          query:{
            isDraft:1,
            draftId:data.id,
            originPageUrL:`/supplierGoodsDraft?currentPage=${this.currentPage}`,
          }
        })
      },
      cancelReviewGoods(row){
        cancelReviewGoods.apply(this,[row.id]).then(()=>{
          this.editDraft(row);
        })
      }
    },
    watch:{
      classifyValue:function(newValue){
        let [firstCateId,secCateId,thirdCateId] = newValue
        this.searchData.firstCateId = firstCateId
        this.searchData.secCateId = secCateId
        this.searchData.thirdCateId = thirdCateId
        this.search()
      },
    }
  }
</script>

<style lang="less">
  .form-item{
    .el-input{
      max-width: 300px;
    }
  }
</style>
<style lang="less" scoped>
  .goods-cover{
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
</style>
